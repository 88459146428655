(function ($) {
	'use strict';
	var fakeData = {

		initReady: function() {

			this.selectText();
			this.socialSharer();

		},

		selectText: function() {

			$('textarea.notepad').focus(function() {
    			var $this = $(this);
			    $this.select();

			    // Work around Chrome's little problem
			    $this.mouseup(function() {
			        // Prevent further mouseup intervention
			        $this.unbind("mouseup");
			        return false;
			    });
    		});
		},

		socialSharer: function() {
			if( $('.gs-social-sharing').length ) {
				//Call jqSocialSharer
				$('.gs-social-sharing a').jqSocialSharer();

				$('.btn-hide').on('click', function(e) {
					e.preventDefault();
					var open = $(this).hasClass('active');
					if(!open) {
						$(this).addClass('active');
						$('.sticky-left').addClass('hide-social');
					} else {
						$(this).removeClass('active');
						$('.sticky-left').removeClass('hide-social');
					}
				});
			}
		},

	};

	$(document).ready(function () {
		fakeData.initReady();
	});

})(jQuery);